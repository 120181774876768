<template>
  <section id="dashboard-camanchaca">
    <ag-grid-table
      :configUrl="historicoSkusConfig"
      :dataUrl="historicoSkusData"
      :editUrl="historicoSkusEdit"
      @selectionChanged="selectionChanged"
      rowSelection="single"
    ></ag-grid-table>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import useApiServices from '@/services/useApiServices.js';


export default {
  data()
  {
    return {

      historicoSkusConfig: useApiServices.historicoSkusConfig,
      historicoSkusData: useApiServices.historicoSkusData,
      historicoSkusEdit: useApiServices.historicoSkusEdit,









    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },

  methods: {

    selectionChanged(rows){

      console.log(rows)

    }


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

